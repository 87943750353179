<template>
    <div>
        <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test-bank.answer')">
            <div class="d-flex flex-row align-items-center mb-4 mt-2">
                <el-checkbox v-for="(answer, key) in question.answers" v-model="answer.is_right" :label="true" @change="setChecked(key)" class="mb-0">{{ answer['text_' + $i18n.locale] }}</el-checkbox>
            </div>
        </form-group>
    </div>
</template>

<script>
export default {
    name: "Boolean",
    props: ['question'],
    mounted() {
        if (!this.question.answers.length) {
            this.question.answers.push({
                'text_ru': 'Правда',
                'text_kz': 'Шындық',
                'is_right': true,
                'type': this.question.type
            }, {
                'text_ru': 'Ложь',
                'text_kz': 'Жалған',
                'is_right': false,
                'type': this.question.type
            })
        }
    },
    methods: {
        setChecked(key) {
            this.question.answers.forEach((i, k) => {
                if (k !== key) {
                    i.is_right = false
                }
            })
        }
    }
}
</script>

<style scoped>

.tests-form-group-btns {
    background: #E3E2F2;
    border-radius: 4px 4px 0 0;
    padding: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tests-form-group-btns button {
    border: none;
    background-color: transparent;
    padding: 0;
}
/deep/ .tests-form-group .signin-label {
    color: #473F95;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
/deep/ .tests-form-group .el-input__inner {
    border: 2px solid #E3E2F2;
}
/deep/ .tests-form-group--header .tests-form-group__textarea {
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    border: 2px solid #E3E2F2;
    font-size: 14px;
}
/deep/ .tests-form-group--header .tests-form-group__textarea::placeholder,
/deep/ .tests-form-group .el-input__inner::placeholder {
    color: #473F95;
    font-size: 13px;
    line-height: 15px;
}
.el-checkbox {
    color: #473F95;
}
.el-checkbox__input.is-checked+.el-checkbox__label,
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #473F95;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
.modal-add-hw__add {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Inter', sans-serif;
    padding: 14px 24px;
    background: #473F95;
    border-radius: 5px;
    display: block;
    border: none;
    margin: 24px 0;
}
</style>