<template>
    <div>
        <div v-for="(answer, key) in question.answers">
            <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test-bank.answer-ru')">
                <quill-editor
                    v-model="answer.text_ru"
                    :options="editorOption"
                    @ready="onEditorReady($event)"
                />
            </form-group>
            <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test-bank.answer-kz')">
                <quill-editor
                    v-model="answer.text_kz"
                    :options="editorOption"
                    @ready="onEditorReady($event)"
                />
            </form-group>
            <div class="d-flex flex-row justify-content-between align-items-center mb-4">
                <el-checkbox @change="setChecked(key)" v-model="answer.is_right" class="mb-0">{{ $t('profile.test-bank.correct') }}</el-checkbox>
                <el-button type="text" @click="removeAnswer(key)">
                    {{ $t('profile.test-bank.delete') }}
                </el-button>
            </div>
        </div>
        <el-button type="primary" icon="el-icon-plus" @click="addAnswer">
            {{ $t('profile.test-bank.add') }}
        </el-button>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
    components: { quillEditor },
    name: "SingleChoise",
    props: ['question'],
    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [['bold', 'italic', 'image']],
                    resize: {}
                }
            },
        }
    },
    mounted() {
        if (!this.question.answers.length) {
            this.addAnswer()
        }
    },
    methods: {
        addAnswer() {
            this.question.answers.push({
                'text_ru': '',
                'text_kz': '',
                'is_right': 0,
                'type': this.question.type
            })
        },
        removeAnswer(key) {
            this.question.answers.splice(key, 1)
        },
        setChecked(key) {
            this.question.answers.forEach((i, k) => {
                if (k !== key) {
                    i.is_right = 0
                }
            })
        },
        onEditorReady(quill) {
            quill.enable(false)
            quill.on('selection-change', function () {
                quill.enable(true)
            })
        }
    }
}
</script>

<style scoped>

.el-button {
    font-family: 'Roboto', sans-serif;
}
.el-button.el-button--default {
    display: block;
    color: #473F95;
    background-color: white;
    border: 1px solid #473F95;
    margin: 10px;
}
.el-button.el-button--primary{
    margin: 10px 10px 20px;
    display: block;
    color: white;
    background-color: #473F95;
    border: 1px solid #473F95;
}
.el-button.el-button--secondary {
    background: #EEEAFB;
    border-color: #EEEAFB;
    margin-right: 10px;
}
.el-button.el-button--danger {
    background: #D23168;
    border-color: #D23168;
}
.el-button--text {
    color: #D23168;
    padding-left: 8px;
    padding-right: 8px;
}
.tab-content .el-button {
    margin-left: 0;
}
.tests-form-group-btns {
    background: #E3E2F2;
    border-radius: 4px 4px 0 0;
    padding: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tests-form-group-btns button {
    border: none;
    background-color: transparent;
    padding: 0;
}
/deep/ .tests-form-group .signin-label {
    color: #473F95;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
/deep/ .tests-form-group .el-input__inner {
    border: 2px solid #E3E2F2;
}
/deep/ .tests-form-group--header .tests-form-group__textarea {
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    border: 2px solid #E3E2F2;
    font-size: 14px;
}
/deep/ .tests-form-group--header .tests-form-group__textarea::placeholder,
/deep/ .tests-form-group .el-input__inner::placeholder {
    color: #473F95;
    font-size: 13px;
    line-height: 15px;
}
.el-checkbox {
    color: #473F95;
}
.el-checkbox__input.is-checked+.el-checkbox__label,
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #473F95;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
.modal-add-hw__add {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Inter', sans-serif;
    padding: 14px 24px;
    background: #473F95;
    border-radius: 5px;
    display: block;
    border: none;
    margin: 24px 0;
}
</style>