<template>
    <div class="answers-tab">
        <form-group class="tests-form-group" :label="$t('profile.test-bank.type')" >
            <el-select @change="resetAnswers" v-model="question.type" class="w-100" :placeholder="$t('profile.test-bank.type-placeholder')">
                <el-option
                    v-for="(item, key) in types"
                    :key="key"
                    :label="item"
                    :value="key">
                </el-option>
            </el-select>
        </form-group>
        <SingleChoise v-if="question.type == 1" :question="question" />
        <MultipleChoise v-if="question.type == 2" :question="question" />
        <Boolean v-if="question.type == 3" :question="question" />
        <WithScore v-if="question.type == 12" :question="question" />
        <div class="modal-add-hw__footer-btns modal-add-hw__footer-btns--answer">
            <div class="d-flex flex-row">
                <el-button v-if="create" type="secondary" class="modal-add-hw__save" @click="createAnswer">
                    {{ $t('profile.test-bank.save') }}
                </el-button>
                <el-button v-else type="secondary" class="modal-add-hw__save" @click="saveAnswer">
                    {{ $t('profile.test-bank.save') }}
                </el-button>
                <el-button type="danger" class="modal-add-hw__cancel" @click="$router.push({ name: 'bank' })">
                    {{ $t('profile.test-bank.reset') }}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import SingleChoise from "./answers/SingleChoise";
import MultipleChoise from "./answers/MultipleChoise";
import Boolean from "./answers/Boolean";
import WithScore from "./answers/WithScore";
import Vue from "vue";
export default {
    name: "AddAnswers",
    props: ['question', 'types', 'create'],
    components: { SingleChoise, MultipleChoise, Boolean, WithScore },
    methods: {
        saveAnswer() {
            let id = this.question.id
            this.$http.post(`${window.API_ROOT}/api/question/update/${id}`, this.question)
                .then((res) => {
                    this.loading = false;
                    if (res.body.status === 'success') {
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Тест сохранен',
                            type: 'success'
                        });
                    }
                    this.$router.push({ name: 'bank' })
                }).catch(() => {
                    this.loading = false;
                })
        },
        createAnswer() {
            this.$http.post(`${window.API_ROOT}/api/question/create`, this.question)
                .then((res) => {
                    this.loading = false;
                    if (res.body.status === 'success') {
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Тест сохранен',
                            type: 'success'
                        });
                    }
                    this.$router.go(this.$router.currentRoute)
                }).catch(() => {
                    this.loading = false;
                })
        },
        resetAnswers() {
            this.question.answers = []
        }
    }
}
</script>

<style scoped>
.answers-tab {
    padding: 20px 38px 50px;
}
.el-button {
    font-family: 'Roboto', sans-serif;
}
.el-button.el-button--default {
    display: block;
    color: #473F95;
    background-color: white;
    border: 1px solid #473F95;
    margin: 10px;
}
.el-button.el-button--primary{
    margin: 10px 10px 20px;
    display: block;
    color: white;
    background-color: #473F95;
    border: 1px solid #473F95;
}
.el-button.el-button--secondary {
    background: #EEEAFB;
    border-color: #EEEAFB;
    margin-right: 10px;
}
.el-button.el-button--danger {
    background: #D23168;
    border-color: #D23168;
}
.el-button--text {
    color: #D23168;
    padding-left: 8px;
    padding-right: 8px;
}
.tab-content .el-button {
    margin-left: 0;
}
.tests-form-group-btns {
    background: #E3E2F2;
    border-radius: 4px 4px 0 0;
    padding: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tests-form-group-btns button {
    border: none;
    background-color: transparent;
    padding: 0;
}
/deep/ .tests-form-group .signin-label {
    color: #473F95;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
/deep/ .tests-form-group .el-input__inner {
    border: 2px solid #E3E2F2;
}
/deep/ .tests-form-group--header .tests-form-group__textarea {
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    border: 2px solid #E3E2F2;
    font-size: 14px;
}
/deep/ .tests-form-group--header .tests-form-group__textarea::placeholder,
/deep/ .tests-form-group .el-input__inner::placeholder {
    color: #473F95;
    font-size: 13px;
    line-height: 15px;
}
.el-checkbox {
    color: #473F95;
}
.el-checkbox__input.is-checked+.el-checkbox__label,
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #473F95;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
.modal-add-hw__add {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Inter', sans-serif;
    padding: 14px 24px;
    background: #473F95;
    border-radius: 5px;
    display: block;
    border: none;
    margin: 24px 0;
}
.modal-add-hw__footer-btns--answer .modal-add-hw__save {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .el-select .el-input.is-focus .el-input__inner,
/deep/ .el-input.is-active .el-input__inner, /deep/  .el-input__inner:focus {
    border-color: #473F95;
}
.tests-form-group__delete {
    color: #D23168;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    border: none;
    background-color: transparent;
    padding: 0;
}
</style>